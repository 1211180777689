import {extend} from 'flarum/extend';
import UserCard from 'flarum/components/UserCard';
import UserPage from 'flarum/components/UserPage';
import UserControls from 'flarum/utils/UserControls';
import Button from 'flarum/components/Button';
import UserMoneyModal from './components/UserMoneyModal';
import Model from 'flarum/Model';
import User from 'flarum/models/User';
import PointList from "./components/PointList";
import LinkButton from 'flarum/components/LinkButton'

app.initializers.add('flarumalshain-flarummoney', function () {
    User.prototype.canEditMoney = Model.attribute('canEditMoney');

    extend(UserCard.prototype, 'infoItems', function (items) {
        if (app.forum.data.attributes['flarumalshain-flarummoney.noshowzero']) {
            if (this.props.user.data.attributes.point !== 0) {
                items.add('point',
                    app.forum.data.attributes['flarumalshain-flarummoney.moneyname'].replace('[point]', this.props.user.data.attributes['point'])
                );
            }
        } else {
            items.add('point',
                app.forum.data.attributes['flarumalshain-flarummoney.moneyname'].replace('[point]', this.props.user.data.attributes['point'])
            );
        }
    });

    extend(UserControls, 'moderationControls', function (items, user) {
        if (user.canEditMoney()) {
            items.add('point', Button.component({
                children: app.translator.trans('flarumalshain-flarummoney.forum.user_controls.money_button'),
                icon: 'fas fa-money-bill',
                onclick: function () {
                    app.modal.show(new UserMoneyModal({user}));
                }
            }));
        }
    });

    app.routes['user.pointlist'] = {path: '/u/:username/pointlist', component: PointList.component()};
    extend(UserPage.prototype, 'navItems', function (items) {
            const user = this.user;
            items.add('pointlist',
                LinkButton.component({
                    href: app.route('user.pointlist', {username: user.username()}),
                    name: 'pointlist',
                    children: 'Point List',
                    icon: 'fas fa-sort-numeric-up'
                }),
                -125
            );
        }
    );
});