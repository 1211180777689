import app from 'flarum/app';
import Page from 'flarum/components/Page';
import Button from 'flarum/components/Button';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import IndexPage from 'flarum/components/IndexPage'
import listItems from 'flarum/helpers/listItems'
import UserPage from 'flarum/components/UserPage';


function UserItem(user) {
    return [
        <table style="width:100%;margin-bottom:15px;">
            <tr>
                <td style="width:50%;text-align:center">{user.username()}</td>
                <td style="width:50%;text-align:center">{user.data.attributes['point']}</td>
            </tr>
        </table>
    ]
}

export default class EnCokPara extends UserPage {
    init() {
        super.init();

        this.loadUser(m.route.param('username'));
        app.setTitle('Point List');

        this.loading = true;
        this.moreResults = false;
        this.users = [];
        this.refresh();
    }

    content() {
        let loading;

        if (this.loading) {
            loading = LoadingIndicator.component();
        } else if (this.moreResults) {
            loading = Button.component({
                children: 'More',
                className: 'Button',
                onclick: this.loadMore.bind(this)
            });
        }

        return [
            <div>
                <div className="ReferansItems" style="text-align: center;margin-bottom:25px;">
                    <label style="color:red;font-size:20px;"><strong>Point List</strong></label>
                </div>

                <table style="width:100%;margin-bottom:15px;">
                    <tr>
                        <th style="width:50%;text-align:center"><u>Username</u></th>
                        <th style="width:50%;text-align:center"><u>Point</u></th>
                    </tr>
                </table>
                {(this.users.map(UserItem))}
            </div>
        ]

    }

    refresh(clear = true) {
        if (clear) {
            this.loading = true;
            this.users = [];
        }

        return this.loadResults().then(
            results => {
                this.users = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    loadResults(offset) {
        const params = {};
        params.page = {
            offset: offset,
            limit: 100
        };
        params.sort = '-point';

        return app.store.find('users', params);
    }

    loadMore() {
        this.loading = true;

        this.loadResults(this.users.length)
            .then(this.parseResults.bind(this));
    }

    parseResults(results) {
        [].push.apply(this.users, results);

        this.loading = false;
        this.moreResults = !!results.payload.links.next;

        m.lazyRedraw();

        return results;
    }
}