import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

export default class UserMoneyModal extends Modal {
    init() {
        super.init();
        this.point = m.prop(this.props.user.data.attributes['point']);
    }

    className() {
        return 'UserMoneyModal Modal--small';
    }

    title() {
        return app.translator.trans('flarumalshain-flarummoney.forum.modal.title', {user: this.props.user});
    }

    content() {
        return (
            <div className="Modal-body">
                <div className="Form">
                    <div className="Form-group">
                        <label>{app.translator.trans('flarumalshain-flarummoney.forum.modal.current')} {app.forum.data.attributes['flarumalshain-flarummoney.moneyname'].replace('[point]', this.props.user.data.attributes['point'])}</label>
                        <input required className="FormControl" type="number" step="any" value={this.point()} oninput={m.withAttr('value', this.point)} />
                    </div>
                    <div className="Form-group">
                        <Button className="Button Button--primary" loading={this.loading} type="submit">
                            {app.translator.trans('flarumalshain-flarummoney.forum.modal.submit_button')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    onsubmit(e) {
        e.preventDefault();

        this.loading = true;

        this.props.user.save({'point':this.point()}).then(
            () => this.hide(),
            this.loaded.bind(this)
        );
    }
}